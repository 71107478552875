import React, { forwardRef } from "react";
import { animated, useSpring } from "react-spring";
import tomato from "assets/img/ingredients/tomato.png";
import garlic from "assets/img/ingredients/garlic.png";
import riceGrain from "assets/img/ingredients/rice-grain.png";
import riceGrainRed from "assets/img/ingredients/rice-grain-red.png";
import riceGrain2 from "assets/img/ingredients/rice-grain-2.png";
import chicken from "assets/img/ingredients/chicken.png";
import cornPiece from "assets/img/ingredients/corn-piece.png";
import tahini from "assets/img/ingredients/tahini.png";
import friesPiece from "assets/img/ingredients/fries-piece.png";
import parsley from "assets/img/ingredients/parsley.png";
import parsley2 from "assets/img/ingredients/parsley-2.png";
import pickle from "assets/img/ingredients/pickle.png";
import lettuce from "assets/img/ingredients/lettuce.png";
import poutineSauce from "assets/img/ingredients/poutine-sauce.png";
import chipotleSauce from "assets/img/ingredients/chipotle-sauce.png";
import useStore from "../store";

const useRotationSpring = (duration = 2000) => {
  const paused = useStore((state) => state.paused);
  // @ts-ignore
  const [style] = useSpring(
    () => ({
      pause: paused,
      config: { duration: duration + Math.random() * 400 - 200 },
      to: async (update, stop) => {
        const direction = Math.random() < 0.5 ? "-" : "";
        while (true) {
          await update({
            transform: `rotate(${direction}360deg)`,
            from: { transform: "rotate(0deg)" },
          });
        }
      },
    }),
    [paused]
  );
  return style;
};

export const Rice = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div {...props} ref={ref}>
      <animated.img
        src={riceGrainRed}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -mt-10'
      />
      <animated.img
        src={riceGrain}
        alt='Rice'
        style={useRotationSpring(700)}
        className='h-5 -ml-3 -mt-3'
      />
      <animated.img
        src={riceGrain}
        alt='Rice'
        style={useRotationSpring(700)}
        className='h-5 ml-3 -mt-4'
      />
      <animated.img
        src={riceGrain}
        alt='Rice'
        style={useRotationSpring(700)}
        className='h-5 ml-3'
      />
      <animated.img
        src={riceGrain2}
        alt='Rice'
        style={useRotationSpring(700)}
        className='h-5 -ml-3 -mt-6'
      />
      <animated.img
        src={riceGrainRed}
        alt='Rice'
        style={useRotationSpring(700)}
        className='h-5 -mt-4'
      />
      <animated.img
        src={riceGrain}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -mt-10'
      />
      <animated.img
        src={riceGrain}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -mt-5'
      />
      <animated.img
        src={riceGrain2}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -ml-2'
      />
      <animated.img
        src={riceGrain2}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -ml-4 -mt-5'
      />
      <animated.img
        src={riceGrain2}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -ml-6 -mt-6'
      />
      <animated.img
        src={riceGrain2}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -ml-2 -mt-3'
      />
      <animated.img
        src={riceGrain2}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -ml-4'
      />
      <animated.img
        src={riceGrain2}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -mt-5'
      />
      <animated.img
        src={riceGrain2}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 -mt-8'
      />
      <animated.img
        src={riceGrainRed}
        alt='Rice'
        style={useRotationSpring(1000)}
        className='h-5 ml-2 -mt-10'
      />
    </div>
  );
});

export const Lettuce = () => {
  return (
    <div>
      <animated.img
        src={lettuce}
        alt='Lettuce'
        style={useRotationSpring(3000)}
        className='w-16'
      />
    </div>
  );
};

export const Corn = () => {
  return (
    <div>
      <animated.img
        src={cornPiece}
        alt='Corn'
        style={useRotationSpring(1000)}
        className='w-6 ml-6'
      />
      <animated.img
        src={cornPiece}
        alt='Corn'
        style={useRotationSpring(1000)}
        className='w-5 ml-8'
      />
      <animated.img
        src={cornPiece}
        alt='Corn'
        style={useRotationSpring(1000)}
        className='w-5 -mb-2'
      />
      <animated.img
        src={cornPiece}
        alt='Corn'
        style={useRotationSpring(1000)}
        className='w-6 ml-4 -mt-10'
      />
      <animated.img
        src={cornPiece}
        alt='Corn'
        style={useRotationSpring(1000)}
        className='w-5 ml-6 -mt-14'
      />
    </div>
  );
};

export const Chicken = () => {
  return (
    <div>
      <animated.img
        src={chicken}
        alt='Chicken'
        style={useRotationSpring(3000)}
        className='w-24 -mt-8'
      />
    </div>
  );
};

export const Tomatos = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div {...props} ref={ref}>
      <animated.img
        src={tomato}
        alt='tomato'
        className='w-12 ml-3'
        style={useRotationSpring(2000)}
      />
      <animated.img
        src={tomato}
        alt='tomato'
        className='w-12 -mt-12 ml-12'
        style={useRotationSpring(2000)}
      />
    </div>
  );
});

export const Tahini = () => {
  return (
    <div>
      <animated.img
        src={tahini}
        alt='Tahini'
        style={useRotationSpring(6000)}
        className='w-16'
      />
    </div>
  );
};

export const Garlic = () => {
  return (
    <div>
      <animated.img
        src={garlic}
        alt='Creamy Garlic'
        className='w-16'
        style={useRotationSpring(5000)}
      />
    </div>
  );
};

export const PoutineSauce = () => {
  return (
    <div>
      <animated.img
        src={poutineSauce}
        alt='Poutine Sauce'
        className='w-16'
        style={useRotationSpring(5000)}
      />
    </div>
  );
};

export const ChipotleSauce = () => {
  return (
    <div>
      <animated.img
        src={chipotleSauce}
        alt='Chipotle Sauce'
        className='w-16'
        style={useRotationSpring(5000)}
      />
    </div>
  );
};

export const Parsley = () => {
  return (
    <div>
      <animated.img
        src={parsley}
        alt='Parsley'
        className='w-20'
        style={useRotationSpring(2000)}
      />
      <animated.img
        src={parsley2}
        alt='Parsley'
        className='w-20 -mt-10'
        style={useRotationSpring(2000)}
      />
    </div>
  );
};

export const Fries = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div {...props} ref={ref}>
      <animated.img
        src={friesPiece}
        alt='fries'
        className='w-12 ml-3'
        style={useRotationSpring(2000)}
      />
      <animated.img
        src={friesPiece}
        alt='fries'
        className='w-12 -mt-10'
        style={useRotationSpring(1500)}
      />
      <animated.img
        src={friesPiece}
        alt='fries'
        className='w-12 -mt-8 ml-4'
        style={useRotationSpring(2000)}
      />
    </div>
  );
});

export const Pickle = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div {...props} ref={ref}>
      <animated.img
        src={pickle}
        alt='pickle'
        className='w-16 ml-3'
        style={useRotationSpring(2000)}
      />
      <animated.img
        src={pickle}
        alt='pickle'
        className='w-16 -mt-12 ml-8'
        style={useRotationSpring(2000)}
      />
    </div>
  );
});
