import useAudioStore from "hooks/useAudioStore";
import React from "react";
import { isMealOnPlate, MealName } from "../store";
import Meal from "./Meal";
import Score from "./Score";
import { ReactComponent as ResumeArrowHead } from "assets/svg/resume-arrowhead.svg";
import { ReactComponent as SoundOn } from "assets/svg/sound-on.svg";
import { ReactComponent as SoundOff } from "assets/svg/sound-off.svg";

type DialogProps = {
  title: string;
};

const Dialog: React.FC<DialogProps> = ({ children, title }) => {
  return (
    <div className='absolute inset-0 bg-black bg-opacity-25 z-50 flex items-center'>
      <div className='bg-light rounded-md m-4 flex-1 -mt-32 p-4 relative'>
        <h1 className='font-bold text-2xl text-center text-primary uppercase'>
          {title}
        </h1>
        {children}
      </div>
    </div>
  );
};

type LevelCompletedDialogProps = {
  onStartNextLevel: () => void;
  nextMeal: MealName;
  score: number;
};

export const LevelCompletedDialog: React.FC<LevelCompletedDialogProps> = ({
  onStartNextLevel,
  nextMeal,
  score,
}) => {
  return (
    <div className='absolute inset-0 bg-black bg-opacity-25 z-50 flex items-center'>
      <div className='bg-light rounded-md m-4 flex-1 -mt-32 relative'>
        <header className='bg-primary text-light py-4'>
          <h1 className='font-bold text-2xl text-center uppercase'>
            {"Recipe Completed"}
          </h1>
          <div className='font-bold uppercase text-2xl text-center leading-none'>
            +1000
          </div>
          <div className='text-center'>
            <span className='text-2xl font-bold uppercase'>
              Score
            </span>
            <Score
              className='text-2xl inline ml-2'
              score={score}
            />
          </div>
        </header>

        <div className='p-4'>
          <h2 className='text-primary font-headline text-4xl text-center leading-none'>
            Next
          </h2>
          <div className='flex justify-center mt-2'>
            <Meal meal={nextMeal} progressPercent={100} onPlate={false} />
          </div>
          <button
            className='action w-full text-2xl mt-4 uppercase'
            onClick={onStartNextLevel}>
            Let's Go
          </button>
        </div>
      </div>
    </div>
  );
};

type LevelStartDialogProps = {
  meal: MealName;
  onStart: () => void;
};

export const StartLevelDialog: React.FC<LevelStartDialogProps> = ({
  meal,
  onStart,
}) => {
  return (
    <Dialog title='Get Ready'>
      <div className='flex justify-center mt-4'>
        <Meal meal={meal} progressPercent={100} onPlate={false}/>
      </div>
      <button className='action w-full text-2xl mt-4' onClick={onStart}>
        Start
      </button>
    </Dialog>
  );
};

type GamePausedDialogProps = {
  meal: MealName;
  mealProgressPercent: number;
  onResume: () => void;
};

export const GamePausedDialog: React.FC<GamePausedDialogProps> = ({
  meal,
  mealProgressPercent,
  onResume,
}) => {
  const soundOn = useAudioStore((state) => state.sound);
  const toggleSound = useAudioStore((state) => state.toggleSound);

  return (
    <Dialog title='Game Paused'>
      <button
        onClick={() => toggleSound()}
        className='-rotate-5 absolute h-8 left-0 ml-4 mt-4 text-primary top-0 transform w-8'>
        {soundOn ? (
          <SoundOn className='fill-current' />
        ) : (
          <SoundOff className='fill-current' />
        )}
      </button>
      <div className='flex justify-center mt-4'>
        <Meal
          meal={meal}
          progressPercent={mealProgressPercent}
          onPlate={isMealOnPlate(meal)}
        />
      </div>
      <button className='action w-full text-2xl mt-4' onClick={onResume}>
        <ResumeArrowHead className='fill-current text-light inline h-4 w-4' />{" "}
        Resume
      </button>
    </Dialog>
  );
};
