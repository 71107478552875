import create from "zustand";
import * as audio from "assets/audio";
import canAutoplay from "can-autoplay";

type State = {
  sound: boolean;
  _soundLoaded: boolean;
  toggleSound: (sound?: boolean) => void;
};

const useAudioStore = create<State>((set, get) => {
  return {
    sound: false,
    _soundLoaded: false,
    toggleSound(sound = !get().sound) {
      set({ sound });

      if (get()._soundLoaded === false) {
        Object.values(audio).forEach(prepareAudioForSafari);
        set({ _soundLoaded: true });
      }

      playBackgroundMusic();
    },
  };
});

function prepareAudioForSafari(audio: HTMLAudioElement) {
  audio.volume = 0;
  audio.muted = true;
  audio.loop = false;
  audio.play();
}

// set initial sound
canAutoplay.audio({ timeout: 500 }).then((r) => {
  useAudioStore.setState({ sound: r.result });

  if (useAudioStore.getState().sound) {
    useAudioStore.getState().toggleSound(true);
  }
});

export default useAudioStore;

export function playBackgroundMusic(play: boolean = true) {
  if (play) playAudio(audio.bg, 0.9, true);
  else audio.bg.pause();
}

export function playAudio(audio: HTMLAudioElement, volume = 0.6, loop = false) {
  if (useAudioStore.getState().sound) {
    audio.currentTime = 0;
    audio.volume = volume;
    audio.muted = false;
    audio.loop = loop;
    audio.play();
  } else audio.pause();
}
