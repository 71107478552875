import React, { useEffect } from "react";
import { ScreenDark as Screen } from "components/Screen";
import { Link } from "react-router-dom";
import Button from "components/Button";
import youMessedUo from "assets/svg/you-messed-up.svg";
import { playAudio } from "hooks/useAudioStore";
import * as audio from "assets/audio";

const LoseScreen = () => {
  useEffect(() => {
    playAudio(audio.lost)
  }, []);

  return (
    <Screen className='flex flex-col justify-center px-8'>
      {/* Badge */}
      <div
        className='absolute p-2 pt-6 rounded-full font-title bg-primary-600 text-light w-20 h-20 leading-none uppercase text-lg transform -rotate-5'
        style={{ top: "-1.2rem", left: "-0.4rem" }}>
        this sucks
      </div>

      <div>
        <img src={youMessedUo} alt="You Messed Up" className="transform -rotate-2"/>

        <Link to='/play'>
          <Button
            className='w-full mt-12 font-bold'
            style={{ fontFamily: "Montserrat" }}>
            Try Again
          </Button>
        </Link>
      </div>
    </Screen>
  );
};

export default LoseScreen;
