import React from "react";
import { ScreenDark as Screen } from "components/Screen";
import { SubmitHandler, useForm } from "react-hook-form";
import logo from "assets/img/logo-slogan-light.png";

type DiscountSubmitionRequestUserInputs = {
  name: string;
  email: string;
  mobile: string;
  address: string;
};

type DiscountSubmitionRequestInputs = DiscountSubmitionRequestUserInputs & {
  app_id: string;
};

const submitDiscount = async (
  inputs: DiscountSubmitionRequestUserInputs
): Promise<string> => {
  if (process.env.NODE_ENV !== "production") return "server response";

  const data: DiscountSubmitionRequestInputs = { ...inputs, app_id: "4" };
  const response = await fetch("/shelbeys/public/api/sendForm", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      appToken: "6019bf0f1c10c05bc1b5733da1425001e1da7f0283ece",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(response.status + " " + response.statusText);
  }

  type JSONResponse = {
    status: boolean;
    code:
      | 200 // success
      | 201; // failed
    message: string;
  };

  const { status, code, message }: JSONResponse = await response.json();

  if (!status) {
    if (code === 201) {
      throw new Error(message);
    }

    throw new Error(
      message || "Unknown error happend check your internet connection."
    );
  }

  return message;
};

const DiscountScreen = () => {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setError,
    clearErrors,
  } = useForm<DiscountSubmitionRequestUserInputs & { server: string }>();

  const onSubmit: SubmitHandler<DiscountSubmitionRequestUserInputs> = async (
    inputs
  ) => {
    try {
      await submitDiscount(inputs);
    } catch (error) {
      setError("server", { type: "server", message: error?.message });
      throw error;
    }
  };

  if (formState.isSubmitSuccessful) {
    return <DiscountSentScreen />;
  }

  return (
    <Screen className='px-8'>
      {/* Badge */}
      <div
        className='absolute px-2 pt-6 rounded-full font-title bg-primary-600 text-light w-20 h-20 text-center text-sm leading-none uppercase transform rotate-20'
        style={{ top: "-0.6rem", right: "-0.4rem" }}>
        Shelby's
        <br />
        Winner
        <div className='h-1 bg-light'></div>
      </div>

      {/* Title */}
      <h1 className='font-headline text-4xl sm:text-5xl leading-none transform -skew-y-3 mr-4 pt-8 sm:pt-24 whitespace-no-wrap'>
        <span className='block text-3xl'>You deserve</span>
        <span className='block mt-2  text-primary-600 font-headline-rust'>
          10% off
        </span>
      </h1>

      {/* Form */}
      <form
        className='mt-4 sm:mt-12'
        onSubmit={(e) => {
          clearErrors("server");
          return handleSubmit(onSubmit)(e);
        }}>
        <label htmlFor='full_name' className='text-2xl'>
          Full Name
        </label>
        <input
          type='name'
          name='name'
          autoComplete='off'
          ref={register}
          className='w-full border-4 border-gray-500 bg-light text-xl font-bold text-gray-800 py-2 px-4'
        />

        <label htmlFor='phone' className='text-2xl sm:mt-4 block'>
          Phone Number
        </label>
        <input
          type='phone'
          name='mobile'
          autoComplete='off'
          ref={register}
          className='w-full border-4 border-gray-500 bg-light text-xl font-bold text-gray-800 py-2 px-4'
        />

        <label htmlFor='email' className='text-2xl sm:mt-4 block'>
          Email
        </label>
        <input
          type='email'
          name='email'
          autoComplete='off'
          ref={register}
          className='w-full border-4 border-gray-500 bg-light text-xl font-bold text-gray-800 py-2 px-4'
        />

        <label htmlFor='email' className='text-2xl sm:mt-4 block'>
          Your Address
        </label>
        <input
          type='address'
          name='address'
          autoComplete='off'
          ref={register}
          className='w-full border-4 border-gray-500 bg-light text-xl font-bold text-gray-800 py-2 px-4'
        />

        <footer className='mt-4'>
          {/* Order Button */}
          <button
            type='submit'
            disabled={formState.isSubmitting}
            className='action w-full text-2xl mb-2 sm:mt-16 py-2 leading-tight'>
            {formState.isSubmitting ? "Wait please" : "GET YOUR DISCOUNT"}
          </button>

          {/* Errors */}
          {errors.server && (
            <ul className='list-disc ml-5 text-red-500 font-black text-lg'>
              {errors.server.message?.split("\n").map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ul>
          )}
        </footer>
      </form>
    </Screen>
  );
};

export const DiscountSentScreen = () => {
  return (
    <Screen className='px-8 flex flex-col justify-center text-center font-bold'>
      <header>
        <img src={logo} alt="Shelby's Game" className='mb-3 mx-auto w-3/4 sm:w-2/3' />
      </header>
      <h1 className='font-headline-rust text-3xl text-primary-600'>
        We hope you enjoyed the game!
      </h1>
      <p className='font-body text-2xl'>
      We’ve sent the discount coupon code to your email.
      </p>
      <a href='http://www.shelbys.ca' target='_blank' rel='noopener noreferrer'>
        <button className='action w-full mt-4 text-3xl'>ORDER NOW</button>
      </a>
    </Screen>
  );
};

export default DiscountScreen;
