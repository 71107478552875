import React from "react";
import { MemoryRouter as Router, Switch, Route } from "react-router-dom";
import StartScreen from "./pages/StartScreen";
import LoseScreen from "pages/LoseScreen";
import WinScreen from "pages/WinScreen";
import DiscountScreen from "pages/DiscountScreen";
import HowToPlayScreen from "pages/HowToPlayScreen";
import GameScreen from "pages/GameScreen/GameScreen";
import LoseCompletedScreen from "pages/LoseCompletedScreen";

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/low-score' component={LoseCompletedScreen} />
        <Route path='/lost' component={LoseScreen} />
        <Route path='/won' component={WinScreen} />
        <Route path='/discount' component={DiscountScreen} />
        <Route path='/how-to-play' component={HowToPlayScreen} />
        <Route path='/play' component={GameScreen} />
        <Route path='/' component={StartScreen} />
      </Switch>
    </Router>
  );
}

export default App;
