import React, { useMemo } from "react";
import { animated, useSpring, config } from "react-spring";
import { LastAction } from "../store";

function random(arr: Array<any>) {
  return arr[Math.floor(Math.random() * arr.length)];
}
type ActionFeedBackProps = {
  action: LastAction;
} & React.HTMLAttributes<HTMLDivElement>;

const ActionFeedBack: React.FC<ActionFeedBackProps> = ({
  action,
  ...props
}) => {
  //@ts-ignore
  const [style] = useSpring(() => ({
    config: config.stiff,
    from: { opacity: 0, transform: `scale(1)` },
    // @ts-ignore
    to: async (update, stop) => {
      await update({ opacity: 1, transform: `scale(1.5)` });
      await update({ opacity: 0, transform: "translate3d(0,-100%,0)" });
    },
  }));

  const actionLabel = useMemo(() => {
    return action === "catch"
      ? random(["Good!", "Keep gowing", "Great"])
      : action === "wrongCatch"
      ? random(["WRONG", "CHECK INGREDIENTS", "NO!"])
      : action === "miss"
      ? random(["Oops", "Watch Out", "Missed!"])
      : null;
  }, []);

  if (actionLabel)
    return (
      <div {...props}>
        <animated.span
          // @ts-ignore
          style={style}
          className={`block text-white ${
            action === "catch" ? "bg-primary" : "bg-red-700"
          }`}>
          {actionLabel}
        </animated.span>
      </div>
    );

  return null;
};

export default ActionFeedBack;
