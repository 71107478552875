import React, { forwardRef } from "react";
import texture from "assets/img/screen-texture.jpg";
import gameBG from "assets/img/game-bg.jpg";

const ScreenWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => {
  return (
    <main
      className={`relative h-full max-w-md mx-auto overflow-hidden pointer-events-none ${className}`}>
      {children}
    </main>
  );
};

const ScreenContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={`h-full relative pointer-events-auto ${className}`}>
      {children}
    </div>
  );
});

const Screen = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, ...props }, ref) => {
    return (
      <ScreenWrapper className='bg-light'>
        <ScreenContent {...props} ref={ref}>
          {children}
        </ScreenContent>
      </ScreenWrapper>
    );
  }
);

const ScreenDark = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  return (
    <ScreenWrapper className='bg-dark text-light'>
      <DarkTexture />
      <ScreenContent {...props} ref={ref}>
        {children}
      </ScreenContent>
    </ScreenWrapper>
  );
});

const ScreenGameRoom = forwardRef<
HTMLDivElement,
React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
return (
  <ScreenWrapper className='bg-dark text-light'>
     <img
      src={gameBG}
      alt='pattern'
      className='absolute inset-0 mx-auto h-full object-cover'
    />
    <ScreenContent {...props} ref={ref}>
      {children}
    </ScreenContent>
  </ScreenWrapper>
);
});

const DarkTexture = () => {
  return (
    <img
      src={texture}
      alt='pattern'
      className='absolute inset-0 mx-auto h-full opacity-75 object-cover'
    />
  );
};

export { ScreenDark, Screen, ScreenGameRoom };
