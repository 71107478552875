import React from "react";
import { useSpring, animated } from "react-spring";

type HealthBarProps = React.HTMLAttributes<HTMLDivElement> & { health: number };

const HealthBar: React.FC<HealthBarProps> = ({
  health,
  className,
  ...props
}) => {
  const style = useSpring({
    transform: `scaleX(${health / 100})`,
    backgroundColor: health < 35 ? "red" : "#E76208",
  });

  return (
    <div
      {...props}
      className={`bg-primary bg-opacity-25 rounded-sm overflow-hidden ${className}`}>
      <animated.div
        // @ts-ignore
        style={style}
        className='h-3 origin-left'></animated.div>
    </div>
  );
};

export default HealthBar;
