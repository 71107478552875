import React from "react";
import mealShawermaPoutin from "assets/img/meals/meal-shawarma-poutin.png";
import poutineSauce from "assets/img/ingredients/poutine-sauce.png";
import arcArrowUp from "assets/svg/arc-arrow-up.svg";
import arcArrowDown from "assets/svg/arc-arrow-down.svg";
import bowl from "assets/img/bowl.png";
import discountBadge from "assets/img/badge-10-off.png";
import { ScreenDark as Screen } from "components/Screen";
import IngredientAvatar from "pages/GameScreen/components/IngredientAvatar";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import HealthBar from "./GameScreen/components/HealthBar";
import Button from "components/Button";
import useStore from "./GameScreen/store";

type HowToPlayScreenComponentProps = {
  winScore: number;
};

export const HowToPlayScreenComponent: React.FC<HowToPlayScreenComponentProps> = ({
  winScore,
}) => {
  const { path, url } = useRouteMatch();
  const { replace } = useHistory();

  return (
    <Switch>
      <Route path={`${path}/2`}>
        <HowToPlayScreenPage2 onNext={() => replace("/")} />
      </Route>
      <Route path={path}>
        <HowToPlayScreenPage1
          onNext={() => replace(`${url}/2`)}
          winScore={winScore}
        />
      </Route>
    </Switch>
  );
};

const HowToPlayScreen = () => {
  const winScore = useStore((state) => state.winScore);
  return <HowToPlayScreenComponent winScore={winScore} />;
};

export default HowToPlayScreen;

type HowToPlayScreenPage1Props = {
  winScore: number;
  onNext: () => void;
};

export const HowToPlayScreenPage1: React.FC<HowToPlayScreenPage1Props> = ({
  winScore,
  onNext,
}) => {
  return (
    <Screen className='flex flex-col font-body font-bold uppercase'>
      <header>
        {/* Ingredients */}
        <section className='flex justify-around bg-dark font-body font-bold border-b-4 border-primary border-opacity-50 py-4 px-2 normal-case'>
          <IngredientAvatar ingredient='Fries' />
          <IngredientAvatar ingredient='Poutine Sauce' />
          <IngredientAvatar ingredient='Parsley' />
        </section>

        <HealthBar health={15} className='mx-2 mt-2' />

        {/* Only These */}
        <p className='sm:text-2xl text-right px-4 my-3 transform rotate-2 relative'>
          <img
            src={arcArrowUp}
            alt='Pointing Arrow'
            className='absolute h-12 right-0 mr-4'
            style={{ bottom: "100%" }}
          />
          MAKE UP SHELBYS MENU ITEM BY CATCHING
          <span className='text-primary'> ONLY THESE INGREDIENTS</span>
        </p>

        {/* Health Bar */}
        <p className=' sm:text-2xl text-left px-4 my-4 transform rotate-2 relative leading-tight'>
          <img
            src={arcArrowUp}
            alt='Pointing Arrow'
            className='absolute h-16 left-0 ml-2'
            style={{ bottom: "100%", transform: "scaleX(-1)" }}
          />
          <span className='text-red-600'>Watch out</span> the
          <br />
          <span className='text-primary'>Health Bar</span>
        </p>

        {/* Score */}
        <p className='absolute right-0 sm:text-2xl -mt-10 leading-tight text-center'>
          <span className='block text-center text-2xl font-headline-rust text-primary'>
            1500
          </span>
          <img
            src={arcArrowUp}
            alt='Pointing Arrow'
            className='h-10 -mt-4 ml-8'
            style={{ transform: "scaleX(-1)", bottom: "90%" }}
          />
          score a Total
          <br />
          <span className='text-primary'>{winScore}</span>
          <br />
          To win
          <br />
          <img src={discountBadge} alt='10% off' className="w-32"/>
        </p>
      </header>

      <div className='relative mx-4 flex-1'>
        {/* Complete The Meal */}
        <figure className='inline-block absolute left-0'>
          <div className='relative w-40 mt-4'>
            <img
              src={mealShawermaPoutin}
              alt='Meal Shawerma Poutin'
              className='opacity-25'
            />
            <img
              src={mealShawermaPoutin}
              alt='Meal Shawerma Poutin'
              className='w-full absolute bottom-0'
              style={{
                clipPath: "polygon(0% 40%, 100% 40%, 100% 100%, 0 100%)",
              }}
            />
          </div>
          <figcaption className='mt-4 ml-6 sm:ml-2 font-headline text-2xl sm:text-3xl leading-tight transform -rotate-5 inline-block relative'>
            Complete
            <br />
            the <span className='text-primary'>meal</span>
          </figcaption>
        </figure>
      </div>

      <div className='text-right'>
        <Button className='px-4 transform rotate-2 m-8' onClick={onNext}>
          OK &rarr;
        </Button>
      </div>
    </Screen>
  );
};

type HowToPlayScreenPage2Props = {
  onNext: () => void;
};

export const HowToPlayScreenPage2: React.FC<HowToPlayScreenPage2Props> = ({
  onNext,
}) => {
  return (
    <Screen className='font-body font-bold uppercase'>
      {/* Ingredients */}
      <section className='flex justify-around bg-dark font-body font-bold border-b-4 border-primary border-opacity-50 py-4 px-2 normal-case'>
        <IngredientAvatar ingredient='Fries' />
        <IngredientAvatar ingredient='Poutine Sauce' done />
        <IngredientAvatar ingredient='Parsley' done />
      </section>

      <HealthBar health={45} className='mx-2 mt-2' />

      {/* Score */}
      <span className='font-headline-rust text-primary absolute right-0 text-2xl mx-4'>
        1500
      </span>

      {/* Meal */}
      <figure className='inline-block absolute left-0 mx-4'>
        <div className='relative w-40 mt-4'>
          <img
            src={mealShawermaPoutin}
            alt='Meal Shawerma Poutin'
            className='opacity-25'
          />
          <img
            src={mealShawermaPoutin}
            alt='Meal Shawerma Poutin'
            className='w-full absolute bottom-0'
            style={{
              clipPath: "polygon(0% 20%, 100% 20%, 100% 100%, 0 100%)",
            }}
          />
        </div>
      </figure>

      <div className='absolute bottom-0 mb-8 right-0 mx-4'>
        <Button
          className='transform -rotate-5 mb-4 sm:mb-8 px-6'
          style={{ bottom: "60%", right: "10%" }}
          onClick={onNext}>
          Got it
        </Button>
        <img
          src={poutineSauce}
          alt='Poutine Sauce'
          className='h-16 mx-auto transform rotate-45'
        />
        <img
          src={arcArrowDown}
          alt='Pointing Arrow'
          className='mx-auto sm:h-32 my-2 h-20'
        />
        <img src={bowl} alt='Bowl' className=' w-40 transform -rotate-10' />
      </div>

      <div className='absolute left-0 bottom-0 mb-10 sm:mb-20 mx-4 sm:text-4xl leading-snug'>
        <p className='mb-10 text-sm sm:text-lg'>
          <span className="text-primary"><span className="font-body">+</span> 1000</span> Completing A Meal
          <br />
          <span className="text-primary"><span className="font-body">+</span> 100</span> Catching Ingredient
          <br />
          <span className="text-red-600"><span className="font-body">-</span> 100</span> Missing Ingredient
          <br />
          <span className="text-red-600"><span className="font-body">-</span> 200</span> Wrong Catch
        </p>
        <p className='transform -skew-y-3'>
          Collect <span className='text-primary'>ingredients</span>
        </p>
      </div>
    </Screen>
  );
};
