import React, { useMemo } from "react";
import { IngredientName } from "../store";
import tomato from "assets/img/avatars/avatar-tomato.png";
import chicken from "assets/img/avatars/avatar-chicken.png";
import corn from "assets/img/avatars/avatar-corn.png";
import fries from "assets/img/avatars/avatar-fries.png";
import garlic from "assets/img/avatars/avatar-garlic.png";
import parsley from "assets/img/avatars/avatar-parsley.png";
import rice from "assets/img/avatars/avatar-rice.png";
import tahini from "assets/img/avatars/avatar-tahini.png";
import pickels from "assets/img/avatars/avatar-pickels.png";
import lettuce from "assets/img/ingredients/lettuce.png";
import poutineSauce from "assets/img/ingredients/poutine-sauce.png";
import chipotleSauce from "assets/img/ingredients/chipotle-sauce.png";
import { ReactComponent as CheckMark } from "assets/svg/check-mark.svg";

type IngredientAvatarProps = {
  ingredient: IngredientName;
  done?: boolean;
};

const IngredientAvatar: React.FC<IngredientAvatarProps> = ({
  ingredient,
  done,
  ...props
}) => {
  const IngredientSrc = useIngredientAvatarImageSrc(ingredient);

  return (
    <figure className='relative flex flex-col items-center' {...props}>
      <img
        src={IngredientSrc}
        className={`w-24 h-24 object-contain mx-auto ${
          done ? "opacity-50" : ""
        }`}
        alt='Ingredient Img'
      />
      {done && (
        <CheckMark className='absolute inset-0 w-10 h-10 mx-2 text-green-500 fill-current' />
      )}
      <figcaption className="text-primary font-bold sm:text-lg mt-1 text-center">{ingredient}</figcaption>
    </figure>
  );
};

export default IngredientAvatar;

function useIngredientAvatarImageSrc(ingredient: IngredientName) {
  return useMemo(() => {
    const ingredientsImages: Record<IngredientName, string> = {
      Tomatos: tomato,
      Chicken: chicken,
      Corn: corn,
      Fries: fries,
      "Creamy Garlic": garlic,
      Parsley: parsley,
      "Basmati Rice": rice,
      Tahini: tahini,
      Pickle: pickels,
      Lettuce: lettuce,
      "Poutine Sauce": poutineSauce,
      "Chipotle Sauce": chipotleSauce,
    };

    return ingredientsImages[ingredient];
  }, [ingredient]);
}
