import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { animated } from "react-spring";
import useMeasure from "react-use-measure";
import mergeRefs from "react-merge-refs";
import { debounce } from "lodash-es";
import useStore, { FallingIngredient, isMealOnPlate } from "./store";
import { useBowlDrag } from "./hooks/useBowlDrag";
import { Link } from "react-router-dom";
import {
  StartLevelDialog,
  LevelCompletedDialog,
  GamePausedDialog,
} from "./components/Dialogs";
import IngredientAvatar from "./components/IngredientAvatar";
import HealthBar from "./components/HealthBar";
import Score from "./components/Score";
import { ScreenGameRoom as Screen } from "components/Screen";
import bowl from "assets/img/bowl.png";
import table from "assets/img/table.png";
import ActionFeedBack from "./components/ActionFeedBack";
import Ingredient from "./components/Ingredient";
import Meal from "./components/Meal";
import Falling from "./components/Falling";
import { linearMap } from "utils";

const GameScreen: React.FC = () => {
  const store = useStore();
  const bowlRef = useRef<HTMLImageElement | null>(null);
  const fieldRef = useRef<HTMLDivElement | null>(null);
  const [bowlMeasureRef, bowlBounds] = useMeasure();
  const [fieldMeasureRef, fieldBounds] = useMeasure();

  const onCatchIngredientDebounced = useOnCatchIngredientDebouncedCallback();

  const { bind, style } = useBowlDrag({
    bowlWidth: bowlBounds.width,
    fieldWidth: fieldBounds.width,
  });

  useChangeScreen();
  useResetStoreOnUnmount();

  const onFallingChange = (
    ingredient: FallingIngredient,
    element: HTMLDivElement
  ) => {
    const bowlBounds = bowlRef.current?.getBoundingClientRect();
    const ingredientBounds = element.getBoundingClientRect();

    if (bowlBounds && ingredientBounds) {
      if (rectsCollides(fixBowlDOMRect(bowlBounds), ingredientBounds)) {
        onCatchIngredientDebounced(ingredient);
      }
    }
  };

  return (
    <Screen ref={mergeRefs([fieldRef, fieldMeasureRef])} {...bind()}>
      <Dialogs />

      <header className='absolute w-full transform rotate-1  z-10'>
        {/* Pause */}
        <button className='absolute ml-2 mt-4 z-10' onClick={store.pause}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            className='fill-current text-primary'>
            <path d='M10 24h-6v-24h6v24zm10-24h-6v24h6v-24z' />
          </svg>
        </button>

        {/* Exit */}
        <Link to='/'>
          <button className='absolute leading-3 m-1 mt-4 right-0 text-6xl text-primary top-0 z-10'>
            ×
          </button>
        </Link>

        <h1 className='flex justify-around w-full px-4 bg-dark py-4'>
          {store.currentLevel.items.map((item) => (
            <IngredientAvatar
              key={item.name}
              ingredient={item.name}
              done={item.done}
            />
          ))}
        </h1>
        <HealthBar health={store.health} />
        <Meal
          meal={store.currentLevel.meal}
          progressPercent={store.currentLevel.progress * 100}
          className='-mt-4'
          style={{ maxWidth: "25ch" }}
          onPlate={false}
        />
        <Score
          score={store.score}
          className='inline-block absolute right-0 mx-4 mt-2 text-3xl text-primary-600'
        />
      </header>

      <Ingredients
        fieldWidth={fieldBounds.width}
        fieldHeight={fieldBounds.height}
        onIngredientFallingChange={onFallingChange}
        onIngredientHitGround={(ingredient) =>
          store.onMissedIngredient(ingredient)
        }
      />

      <ActionFeedBack
        key={store.lastActionIndex}
        action={store.lastAction}
        className='absolute right-0 left-0 bottom-0 mb-20 sm:mb-32 mx-10 font-headline-rust text-lg sm:text-2xl p-2 transform -rotate-1 block text-center'
      />

      {/* Bowl */}
      <animated.img
        alt='Bowl'
        src={bowl}
        ref={mergeRefs([bowlRef, bowlMeasureRef])}
        style={style}
        className='absolute bottom-0 mb-4 w-1/3 pointer-events-none transform -translate-x-1/2 z-10 shadow-xl'
      />

      <img
        src={table}
        alt='Table'
        className='absolute bottom-0 pointer-events-none h-12 w-full'
      />
    </Screen>
  );
};

type IngredientsProps = {
  fieldHeight: number;
  fieldWidth: number;
  onIngredientFallingChange: (
    ingredient: FallingIngredient,
    ref: HTMLDivElement
  ) => void;
  onIngredientHitGround: (ingredient: FallingIngredient) => void;
};

const Ingredients: React.FC<IngredientsProps> = ({
  fieldHeight,
  fieldWidth,
  onIngredientFallingChange,
  onIngredientHitGround,
}) => {
  const fallingIngredients = useStore((state) => state.fallingIngredients);
  const paused = useStore((state) => state.paused);
  const fallDuration = useFallDuration();
  const ingredientsRef = useRef<Record<number, HTMLDivElement>>({});

  useEffect(() => {
    if (fallingIngredients.length === 0) {
      ingredientsRef.current = {};
    }
  }, [fallingIngredients]);

  return (
    <>
      {fallingIngredients.map((ingredient, index) => (
        <Falling
          key={ingredient.id}
          ref={(ref) => {
            if (ref !== null) ingredientsRef.current[ingredient.id] = ref;
          }}
          pause={paused}
          delayIndex={index}
          fieldHeight={fieldHeight}
          fieldWidth={fieldWidth}
          fallDuration={fallDuration}
          onHitGround={() => onIngredientHitGround(ingredient)}
          onFrame={() => {
            ingredientsRef.current[ingredient.id] &&
              onIngredientFallingChange(
                ingredient,
                ingredientsRef.current[ingredient.id]
              );
          }}
          className='absolute inline-block pointer-events-none'>
          <Ingredient ingredient={ingredient.name} />
        </Falling>
      ))}
    </>
  );
};

const Dialogs = () => {
  const score = useStore((state) => state.score);
  const paused = useStore((state) => state.paused);
  const resume = useStore((state) => state.resume);
  const meal = useStore((state) => state.currentLevel.meal);
  const currentLevel = useStore((state) => state.currentLevel);
  const nextLevel = useStore((state) => state.getNextLevel());
  const startNextLevel = useStore((state) => state.startNextLevel);
  const completed = useStore((state) => state.currentLevel.completed);
  const currentLevelNumber = useStore((state) => state.getCurrentLevelNumber());
  const [showStartDialog, setShowStartDialog] = useState(true);

  if (completed && nextLevel)
    return (
      <LevelCompletedDialog
        score={score}
        nextMeal={nextLevel.meal}
        onStartNextLevel={startNextLevel}
      />
    );

  if (currentLevelNumber === 1 && showStartDialog)
    return (
      <StartLevelDialog
        meal={meal}
        onStart={() => {
          setShowStartDialog(false);
          resume();
        }}
      />
    );

  if (paused)
    return (
      <GamePausedDialog
        meal={meal}
        mealProgressPercent={currentLevel.progress * 100}
        onResume={resume}
      />
    );

  return null;
};

function rectsCollides(rect1: DOMRect, rect2: DOMRect) {
  return !(
    rect1.top > rect2.bottom ||
    rect1.right < rect2.left ||
    rect1.bottom < rect2.top ||
    rect1.left > rect2.right
  );
}

function fixBowlDOMRect(rect: DOMRect) {
  const y = rect.y + rect.height * 0.25;
  const x = rect.x + rect.width * 0.3;
  const width = rect.width * 0.3;
  const height = rect.height * 0.5;

  return new DOMRect(x, y, width, height);
}

function useResetStoreOnUnmount() {
  const resetStore = useStore((state) => state.reset);

  useEffect(() => {
    return () => {
      // Reset store on screen un-mount's
      resetStore();
    };
  }, [resetStore]);
}

function useChangeScreen() {
  const history = useHistory();
  const levelLost = useStore((state) => state.isLevelLost());
  const win = useStore((state) => state.didWin());
  const completeWithLowScore = useStore((state) =>
    state.didCompleteWithScoreLow()
  );

  useEffect(() => {
    if (win) history.replace("/won");
    else if (levelLost) history.replace("/lost");
    else if (completeWithLowScore) history.replace("/low-score");
  }, [history, win, levelLost, completeWithLowScore]);
}

function useFallDuration() {
  const levelNumber = useStore((state) => state.getCurrentLevelNumber());
  const levelsCount = useStore((state) => state.getLevelsCount());
  const minDuration = 2000;
  const maxDuration = 1100;
  return useMemo(
    () => linearMap(1, levelsCount, minDuration, maxDuration, levelNumber),
    [levelNumber, levelsCount]
  );
}

function useOnCatchIngredientDebouncedCallback() {
  const fallingIngredients = useStore((state) => state.fallingIngredients);
  const onCatchIngredient = useStore((state) => state.onCatchIngredient);
  const callbacks = useRef<Record<number, typeof onCatchIngredient>>({});

  useEffect(() => {
    callbacks.current = {};
    for (const ingredient of fallingIngredients) {
      callbacks.current[ingredient.id] = debounce(onCatchIngredient, 400, {
        leading: true,
        trailing: false,
      });
    }
  }, [fallingIngredients, onCatchIngredient]);

  return useCallback((ingredient: FallingIngredient) => {
    return callbacks.current[ingredient.id](ingredient);
  }, []);
}

export default GameScreen;
