import React, { useMemo } from "react";

import riceBowl from "assets/img/meals/meal-chicken-rice-bowl.png";
import shelbysWrap from "assets/img/meals/meal-shelbys-wrap.png";
import shawarmaBurito from "assets/img/meals/meal-shawarma-burito.png";
import shawarmaPoutin from "assets/img/meals/meal-shawarma-poutin.png";
import traditionalWrap from "assets/img/meals/meal-traditional-wrap.png";
import { animated, useSpring } from "react-spring";
import { MealName } from "../store";

type MealProps = React.HTMLAttributes<HTMLDivElement> & {
  meal: MealName;
  progressPercent: number;
  onPlate: boolean;
};

const Meal: React.FC<MealProps> = ({
  meal,
  progressPercent,
  onPlate,
  className,
  ...props
}) => {
  const src = useMealAvatarImageSrc(meal);
  const style = useSpring({
    clipPath: `polygon(0% ${100 - progressPercent}%, 100% ${
      100 - progressPercent
    }%, 100% 100%, 0 100%)`,
  });

  return (
    <figure {...props} className={`inline-block relative ${className}`}>
      <div className='relative w-32 sm:w-40 z-0 mx-auto'>
        {/* Plate */}
        {onPlate && (
          <div className='flex absolute inset-0 items-center justify-center -z-1'>
            <div className='h-32 w-32 bg-white shadow-lg rounded-full transform scale-110 sm:scale-125 p-4'>
              <div className='h-full w-full bg-white shadow-inner rounded-full'></div>
            </div>
          </div>
        )}

        <img src={src} alt='Meal Shawerma Poutin' className='opacity-25 z-10' />
        <animated.img
          src={src}
          alt='Meal Shawerma Poutin'
          // @ts-ignore
          style={style}
          className='w-full absolute bottom-0 z-0'
        />
      </div>

      <figcaption
        className='text-center text-white bg-primary-600 text-xl sm:text-2xl leading-tight py-1 sm:py-2 px-3 mt-3 transform rotate-2'
        style={{ fontFamily: "Norwester" }}>
        {meal.toUpperCase()}
      </figcaption>
    </figure>
  );
};

export default Meal;

function useMealAvatarImageSrc(meal: MealName) {
  return useMemo(() => {
    const mealsSrcs: Record<MealName, string> = {
      "Shelbys Wrap": shelbysWrap,
      "Rice Bowl": riceBowl,
      "Shawarma Burrito": shawarmaBurito,
      "Shawarma Fries Poutine": shawarmaPoutin,
      "Traditional Wrap": traditionalWrap,
    };

    return mealsSrcs[meal];
  }, [meal]);
}
