const bg = new Audio(require("./jazz-1.mp3"));
const select1 = new Audio(require("./select-1.wav"));
const select2 = new Audio(require("./select-2.wav"));
const pickup = new Audio(require("./pick-up.mp3"));
const missed = new Audio(require("./missed.mp3"));
const lost = new Audio(require("./lost.mp3"));
const levelup = new Audio(require("./level-up.mp3"));
const winnerMusic = new Audio(require("./winning-music.mp3"));

export { bg, select1, select2, pickup, missed, lost, levelup, winnerMusic };
