import React, { useMemo } from "react";
import { IngredientName } from "../store";
import {
  Tomatos,
  Garlic,
  Rice,
  Chicken,
  Corn,
  Tahini,
  Parsley,
  Fries,
  Pickle,
  Lettuce,
  PoutineSauce,
  ChipotleSauce,
} from "./Ingredients";

type IngredientProps = {
  ingredient: IngredientName;
};

const Ingredient: React.FC<IngredientProps> = ({ ingredient }) => {
  const IngredientComponent = useIngredientComponent(ingredient);

  return IngredientComponent ? <IngredientComponent /> : null;
};

export default Ingredient;

function useIngredientComponent(ingredient: IngredientName) {
  return useMemo(() => {
    const ingredientsComponents: Record<IngredientName, React.FC<any>> = {
      Tomatos,
      "Creamy Garlic": Garlic,
      "Basmati Rice": Rice,
      Chicken,
      Corn,
      Tahini,
      Parsley,
      Fries,
      Pickle,
      Lettuce,
      "Poutine Sauce": PoutineSauce,
      "Chipotle Sauce": ChipotleSauce,
    };

    return ingredientsComponents[ingredient];
  }, [ingredient]);
}
