import React, { useEffect } from "react";
import { ScreenDark as Screen } from "components/Screen";
import { Link } from "react-router-dom";
import Button from "components/Button";
import useStore from "./GameScreen/store";
import { playAudio } from "hooks/useAudioStore";
import * as audio from "assets/audio";

type LoseCompletedScreenComponentProps = {
  score: number;
  winScore: number;
};

export const LoseCompletedScreenComponent: React.FC<LoseCompletedScreenComponentProps> = ({
  score,
  winScore,
}) => {
  useEffect(() => {
    playAudio(audio.lost)
  }, []);

  return (
    <Screen className='flex flex-col justify-center px-8 text-center font-headline'>
      {/* Badge */}
      <div
        className='absolute p-2 pt-6 rounded-full font-title bg-primary-600 text-light w-20 h-20 leading-none uppercase text-lg transform -rotate-5'
        style={{ top: "-1.2rem", left: "-0.4rem" }}>
        this sucks
      </div>

      <div>
        <h1 className='text-6xl sm:text-6xl leading-none'>
          <span className='mr-8 sm:mr-24 block transform -rotate-2'>You</span>
          <span className='block mt-2 ml-12 sm:ml-40 text-primary-600 font-headline-rust transform rotate-2'>
            Lost
          </span>
        </h1>

        <div className='text-3xl sm:text-4xl mt-4'>
          score{" "}
          <span className='text-primary-600 font-headline-rust'>{score}</span>
        </div>

        <div className='my-2 sm:my-4 text-xl sm:text-2xl leading-relaxed'>
          Meals Completed <span className='text-primary'>But</span>
          <br />
          Score More Than <span className='text-primary'>{winScore}</span>
          <br />
          To Win
        </div>
        <Link to='/play'>
          <Button className='w-full mt-6'>Try Again</Button>
        </Link>
      </div>
    </Screen>
  );
};

const LoseCompletedScreen = () => {
  const score = useStore((state) => state.score);
  const winScore = useStore((state) => state.winScore);

  return <LoseCompletedScreenComponent score={score} winScore={winScore} />;
};

export default LoseCompletedScreen;
