import React, { useEffect, useRef } from "react";
import { animated, config, useSpring } from "react-spring";

type ScoreProps = {
  score: number;
};

const Score: React.FC<React.HTMLAttributes<HTMLDivElement> & ScoreProps> = ({
  score,
  ...props
}) => {
  const scoreRef = useRef(score);
  useEffect(() => {
    scoreRef.current = score;
  });
  const prevScore = scoreRef.current;

  const spring = useSpring({ score, config: config.slow });
  const [style] = useSpring(
    () => ({
      from: { transform: "scale(1) rotate(0deg)" },
      to: async (update, stop) => {
        const decreased = score - prevScore < 0;
        await update({
          transform: `scale(${decreased ? "0.8" : "1.5"}) rotate(${
            decreased ? "0" : "5"
          }deg)`,
        });
        await update({ transform: "scale(1) rotate(0deg)" });
      },
      config: config.stiff,
    }),
    [score]
  );

  return (
    <div {...props}>
      <animated.span
        style={style}
        className='tabular-nums inline-block font-bold'>
        {spring.score.to((x) => Math.round(x))}
      </animated.span>
    </div>
  );
};

export default Score;
