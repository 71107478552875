import create from "zustand";
import * as audio from "assets/audio";
import { playAudio, playBackgroundMusic } from "hooks/useAudioStore";
import { clamp, probability, random, shuffle } from "utils";
import { useEffect } from "react";

const ingredients = [
  "Creamy Garlic",
  "Basmati Rice",
  "Chicken",
  "Corn",
  "Tahini",
  "Parsley",
  "Fries",
  "Pickle",
  "Poutine Sauce",
  "Chipotle Sauce",
  "Lettuce",
  "Tomatos",
] as const;

const meals = [
  "Shawarma Fries Poutine",
  "Rice Bowl",
  "Shelbys Wrap",
  "Shawarma Burrito",
  "Traditional Wrap",
] as const;

export type IngredientName = typeof ingredients[number];
export type MealName = typeof meals[number];

const mealsRecipes: Record<MealName, IngredientName[]> = {
  "Shelbys Wrap": ["Tomatos", "Tahini", "Lettuce"],
  "Rice Bowl": ["Basmati Rice", "Creamy Garlic", "Tahini"],
  "Shawarma Burrito": ["Basmati Rice", "Corn", "Chipotle Sauce"],
  "Shawarma Fries Poutine": ["Parsley", "Fries", "Poutine Sauce"],
  "Traditional Wrap": ["Chicken", "Pickle", "Creamy Garlic"],
};

const mealsWrongIngredients: Record<MealName, IngredientName[]> = {
  "Shelbys Wrap": ["Chipotle Sauce", "Corn", "Parsley"],
  "Rice Bowl": ["Poutine Sauce", "Parsley", "Fries"],
  "Shawarma Burrito": ["Poutine Sauce", "Parsley", "Tahini"],
  "Shawarma Fries Poutine": ["Pickle", "Tahini", "Basmati Rice"],
  "Traditional Wrap": ["Lettuce", "Tomatos", "Tahini"],
};

const mealsOnPlate = ["Shawarma Fries Poutine", "Rice Bowl"];

type Level = {
  readonly meal: MealName;
  readonly recipe: IngredientName[];
  readonly ingredientsLeft: IngredientName[];
  readonly wrongIngredients: IngredientName[];
  readonly completed: boolean;
  readonly items: { name: IngredientName; done: boolean }[];
  /**
   * @returns 0 to 1 progress
   */
  readonly progress: number;
  canCatchIngredient: (ingredient: IngredientName) => boolean;
  setItemsCatched: (ingredient: IngredientName) => void;
};

export type LastAction = "miss" | "catch" | "wrongCatch" | null;

type State = {
  /**
   * 0 to 100
   */
  health: number;
  score: number;
  winScore: number;
  paused: boolean;
  currentLevel: Level;
  lastAction: LastAction;
  /**
   * For determining if last action changed even
   * if to same action
   */
  lastActionIndex: number;
  fallingIngredients: FallingIngredient[];
  getCurrentLevelNumber: () => number;
  getNextLevel: () => Level | null;
  getLevelsCount: () => number;
  isLevelLost: () => boolean;
  /**
   * Could be lost or won
   */
  isGameFinished: () => boolean;
  /**
   * Winned the whole game
   */
  didWin: () => boolean;
  didCompleteWithScoreLow: () => boolean;

  // Actions
  pause: () => void;
  resume: () => void;
  startNextLevel: () => void;
  onCatchIngredient: (ingredient: FallingIngredient) => void;
  onMissedIngredient: (ingredient: FallingIngredient) => void;
  reset: () => void;
};

export type FallingIngredient = {
  id: number;
  name: IngredientName;
};

let useStore = create<State>((set, get) => {
  function getLevels() {
    return shuffle(meals.slice()).map(createLevelForMeal);
  }

  function getNextIngredients(currentLevel?: Level): FallingIngredient[] {
    if (get().fallingIngredients.length) return get().fallingIngredients; // wait until no falling ingredients

    const level: Level = currentLevel || get().currentLevel;
    const levelNumber = get().getCurrentLevelNumber();
    const levelsCount = get().getLevelsCount();
    const startDoubling = levelNumber > levelsCount / 2;

    if (startDoubling && probability(0.3)) {
      const ings = [];
      const ing1 = random(
        probability(0.25) ? level.ingredientsLeft : level.wrongIngredients
      );

      const ing2 = random(
        probability(0.25)
          ? level.ingredientsLeft.filter((e) => e !== ing1)
          : level.wrongIngredients.filter((e) => e !== ing1)
      );

      ings.push({ id: ++nextFallingIngredientId, name: ing1 });
      if (ing2) ings.push({ id: ++nextFallingIngredientId, name: ing2 });

      return ings;
    } else {
      return [
        {
          id: ++nextFallingIngredientId,
          name: random(
            probability(0.3) ? level.ingredientsLeft : level.wrongIngredients
          ),
        },
      ];
    }
  }

  function isAllLevelsCompleted() {
    return levels.every((level) => level.completed);
  }

  function canWinWithCurrentScore() {
    const { score, winScore } = get();
    return score > winScore;
  }

  function getInitialState() {
    return {
      score: 0,
      winScore: 3600,
      currentLevel: levels[0],
      lastAction: null,
      lastActionIndex: 0,
      health: 100,
      fallingIngredients: [{ id: 0, name: random(ingredients) }],
      paused: true, // starts pausing
    };
  }

  let levels = getLevels();
  let nextFallingIngredientId = 0;

  return {
    ...getInitialState(),
    reset() {
      levels = getLevels();
      nextFallingIngredientId = 0;
      set({ ...getInitialState() });
    },
    pause() {
      set({ paused: true });
    },
    resume() {
      set({
        paused: false,
        fallingIngredients: [
          { id: ++nextFallingIngredientId, name: random(ingredients) },
        ],
      });
    },
    getLevelsCount() {
      return levels.length;
    },
    getCurrentLevelNumber() {
      const index = levels.findIndex((level) => this.currentLevel === level);
      return index + 1;
    },
    getNextLevel() {
      const currentLevelNumber = this.getCurrentLevelNumber();
      if (currentLevelNumber === levels.length) return null;
      return levels[currentLevelNumber];
    },
    isLevelLost() {
      return get().health <= 0;
    },
    isGameFinished() {
      return get().isLevelLost() || isAllLevelsCompleted();
    },
    didWin() {
      return isAllLevelsCompleted() && canWinWithCurrentScore();
    },
    didCompleteWithScoreLow() {
      return isAllLevelsCompleted() && !canWinWithCurrentScore();
    },

    // Actions

    startNextLevel() {
      if (get().didWin()) return;

      set({ currentLevel: levels[get().getCurrentLevelNumber()] });

      set((state) => ({
        health: 100,
        lastAction: null,
        lastActionIndex: state.lastActionIndex + 1,
        fallingIngredients: getNextIngredients(),
      }));
    },

    onCatchIngredient(ingredient) {
      set({
        fallingIngredients: get().fallingIngredients.filter(
          (e) => ingredient.id !== e.id
        ),
      });

      const { currentLevel } = get();

      const goodCatch = currentLevel.canCatchIngredient(ingredient.name);

      if (goodCatch) currentLevel.setItemsCatched(ingredient.name);

      if (
        currentLevel.completed &&
        get().getCurrentLevelNumber() === get().getLevelsCount()
      ){
        playBackgroundMusic(false);
        playAudio(audio.winnerMusic, 1);
      }
      else {
      playAudio(
        currentLevel.completed
          ? audio.levelup
          : goodCatch
          ? audio.pickup
          : audio.missed
      );
      }

      set((state) => ({
        health: clamp(0, 100, state.health + (goodCatch ? 20 : -20)),
        score: state.score + (goodCatch ? 100 : -100),
      }));

      if (!goodCatch) {
        set((state) => ({ score: state.score - 100 }));
      }

      if (currentLevel.completed) {
        set((state) => ({ score: state.score + 1000 }));
      } else {
        set({ fallingIngredients: getNextIngredients() });
      }

      set((state) => ({
        lastAction: goodCatch ? "catch" : "wrongCatch",
        lastActionIndex: state.lastActionIndex + 1,
      }));
    },

    onMissedIngredient(ingredient) {
      set({
        fallingIngredients: get().fallingIngredients.filter(
          (e) => ingredient.id !== e.id
        ),
      });

      const { currentLevel } = get();

      if (currentLevel.completed) return;

      const miss = currentLevel.canCatchIngredient(ingredient.name);

      if (miss) {
        playAudio(audio.missed);
        set((state) => ({
          health: clamp(0, 100, state.health - 20),
          score: state.score - 100,
          lastAction: "miss",
          lastActionIndex: state.lastActionIndex + 1,
        }));
      }

      set({ fallingIngredients: getNextIngredients() });
    },
  };
});

export default useStore;

// Hooks

export function useLevelLost(onLevelLost: () => void) {
  const health = useStore((state) => state.health);
  useEffect(() => {
    if (health <= 0) onLevelLost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [health]);
}

// Helpers

function createLevelForMeal(meal: MealName): Level {
  const recipe = mealsRecipes[meal].map((ingredient) => ({
    name: ingredient,
    done: false,
  }));

  return {
    get meal() {
      return meal;
    },
    get recipe() {
      return recipe.map((item) => item.name);
    },
    get items() {
      return recipe;
    },
    get ingredientsLeft() {
      return recipe.filter((item) => !item.done).map((item) => item.name);
    },
    get wrongIngredients() {
      return mealsWrongIngredients[meal];
    },
    get completed() {
      return recipe.every((ingredient) => ingredient.done);
    },
    get progress() {
      return (
        recipe.filter((ingredient) => ingredient.done).length / recipe.length
      );
    },
    canCatchIngredient(ingredient) {
      return recipe.some(
        (recipeIngredient) => recipeIngredient.name === ingredient
      );
    },
    setItemsCatched(ingredient) {
      const item = recipe.find((e) => e.name === ingredient);
      if (item) item.done = true;
    },
  };
}

export function isMealOnPlate(meal: MealName) {
  return mealsOnPlate.findIndex((x) => x === meal) !== -1;
}
