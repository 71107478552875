import React from "react";
import { Link } from "react-router-dom";
import { ScreenDark as Screen } from "components/Screen";
import badge from "assets/img/badge-10-off.png";
import Button from "components/Button";

const WinScreen = () => {
  return (
    <Screen className='flex flex-col justify-center px-8'>
      <div>
        <header className='flex items-center justify-center'>
          {/* You Win */}
          <h1 className='font-headline text-6xl leading-none transform -skew-y-3 mr-4'>
            <span className='block'>You</span>
            <span className='block mt-2  text-primary-600 font-headline-rust '>
              Won
            </span>
          </h1>

          {/* Badge */}
          <img src={badge} alt='10% Off' className='w-40 transform rotate-10' />
        </header>

        {/* Order Button */}
        <Link to='/discount'>
          <Button className='w-full mt-6 px-1 action'>GET YOUR DISCOUNT</Button>
        </Link>
      </div>
    </Screen>
  );
};

export default WinScreen;
