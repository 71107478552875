export function clamp(min: number, max: number, value: number) {
  return Math.max(min, Math.min(max, value));
}

export function random<T>(arr: ReadonlyArray<T>) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function linearMap(
  min1: number,
  max1: number,
  min2: number,
  max2: number,
  value1: number
) {
  return ((value1 - min1) * (max2 - min2)) / (max1 - min1) + min2;
}

export function shuffle<T>(array: Array<T>) {
  const arr = array.slice();
  for (var i = arr.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
}

/**
 *
 * @param probability 0 - 1
 */
export function probability(probability: number) {
  return Math.random() < probability;
}
