import React from "react";
import { Link } from "react-router-dom";
import header from "assets/svg/start-screen-header.svg";
import footer from "assets/svg/start-screen-footer.svg";
import { ScreenDark as Screen } from "components/Screen";
import useAudioStore, { playAudio } from "hooks/useAudioStore";
import * as audio from "assets/audio";
import { ReactComponent as SoundOn } from "assets/svg/sound-on.svg";
import { ReactComponent as SoundOff } from "assets/svg/sound-off.svg";

const StartScreen = () => {
  const sound = useAudioStore((state) => state.sound);
  const toggleSound = useAudioStore((state) => state.toggleSound);

  return (
    <Screen className='flex flex-col px-6 sm:px-10'>
      <div className='absolute m-4 z-10 flex items-center'>
        <button
          onClick={() => toggleSound()}
          className='font-headline-rust text-light text-2xl sm:text-3xl flex items-center'>
          Sound {sound ? "On" : "Off"}
        {sound ? (
          <SoundOn className='fill-current h-8 mx-4' />
        ) : (
          <SoundOff className='fill-current h-8 mx-4 animate-bounce-x' />
        )}
        </button>
      </div>

      <header>
        <img
          src={header}
          alt="Shelby's Game"
          className='w-full object-cover transform scale-125 origin-bottom'
        />
      </header>

      <div className='flex-1 py-4 flex flex-col justify-center'>
        <Link to='/play'>
          <button
            className='action w-full focus:outline-none outline-none text-3xl sm:text-5xl font-headline-rust transform -rotate-2 hover:rotate-2 transition-transform duration-200 shadow-md'
            onClick={() => playAudio(audio.select1)}>
            <span className='block transform rotate-2'>Start</span>
          </button>
        </Link>
        <Link to='/how-to-play'>
          <button
            className='w-full border-4 border-solid text-3xl sm:text-5xl text-light border-light focus:bg-light hover:bg-light hover:bg-opacity-25 focus:bg-opacity-25 focus:outline-none outline-none py-3 mt-4 rounded-full font-bold'
            onClick={() => playAudio(audio.select2)}>
            How to play
          </button>
        </Link>
      </div>

      <footer>
        <a href='http://shelbys.ca' target='_blank' rel='noopener noreferrer'>
          <img
            src={footer}
            alt="Shelby's Logo"
            className='w-full object-cover transform scale-125 origin-top mb-16'
          />
        </a>
      </footer>
    </Screen>
  );
};

export default StartScreen;
