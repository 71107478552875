import { useDrag } from "react-use-gesture";
import { useSpring, config } from "react-spring";

export const useBowlDrag = (options: {
  bowlWidth: number;
  fieldWidth: number;
}) => {
  const [style, set] = useSpring(() => ({
    transform: `translate3d(100%,0,0)`,
    config: config.stiff,
  }));
  return {
    style,
    bind: useDrag(
      ({ offset }) => {
        const { bowlWidth, fieldWidth } = options;
        if (!bowlWidth && !fieldWidth) return;
        const x =
          (((offset[0] * 100) / fieldWidth) * fieldWidth) / bowlWidth + 50;
        set({
          transform: `translate3d(${x}%, 0, 0)`,
        });
      },
      {
        axis: "x",
      }
    ),
  };
};
