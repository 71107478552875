import React from "react";
import * as audio from "assets/audio";
import { playAudio } from "hooks/useAudioStore";

const Button: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <button
      {...props}
      className={`focus:outline-none outline-none py-3 text-2xl sm:text-4xl rounded-xl bg-primary focus:bg-primary-dark hover:bg-primary-dark text-center text-light font-body font-bold uppercase ${props.className}`}
      onClick={(e) => {
        playAudio(audio.select1);
        props.onClick && props.onClick(e);
      }}
    />
  );
};

export default Button;
