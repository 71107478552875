import React, { forwardRef, useCallback, useMemo, useRef } from "react";
import { animated, useSpring } from "react-spring";
import useMeasure from "react-use-measure";
import mergeRefs from "react-merge-refs";
import { throttle } from "lodash-es";

type FallingProps = {
  fieldWidth: number;
  fieldHeight: number;
  fallDuration: number;
  delayIndex: number;
  pause?: boolean;
  onFrame: () => void;
  onHitGround: () => void;
} & React.HTMLAttributes<HTMLDivElement>;
const Falling = forwardRef<HTMLDivElement, FallingProps>(
  (
    {
      fieldWidth,
      fieldHeight,
      fallDuration,
      onFrame,
      onHitGround,
      children,
      pause,
      delayIndex,
      ...props
    },
    ref
  ) => {
    const ingredientRef = useRef<HTMLDivElement>();
    const [ingredientMeasureRef, ingredientBounds] = useMeasure();
    const onFrameDebounced = useCallback(
      throttle(onFrame, 16, { leading: true, trailing: false }),
      []
    );

    const [fromX, toX, fromY, toY] = useMemo(() => {
      const fromX =
        (fieldWidth * (Math.random() * 80)) / 100 + (fieldWidth * 10) / 100;
      const toX =
        (fieldWidth * (Math.random() * 80)) / 100 + (fieldWidth * 10) / 100;
      const fromY = -Math.max(ingredientBounds.height || 1, ingredientBounds.width);
      const toY =
        fieldHeight + Math.max(ingredientBounds.height || 1, ingredientBounds.width);
      return [fromX, toX, fromY, toY];
    }, [
      fieldHeight,
      fieldWidth,
      ingredientBounds.height,
      ingredientBounds.width,
    ]);

    const delay = useMemo(() => delayIndex * (Math.random() * 1000 + 400), []);

    const [style] = useSpring(
      () => ({
        pause,
        delay,
        reset: true,
        onChange: onFrameDebounced,
        config: { duration: fallDuration },
        from: { transform: `translate3d(${fromX}px, ${fromY}px, 0)` },
        to: async (update, stop) => {
          await update({ transform: `translate3d(${toX}px, ${toY}px, 0)` });
          onHitGround();
        },
        cancel:
          !fieldHeight ||
          !fieldWidth ||
          !ingredientBounds.height ||
          !ingredientBounds.width,
      }),
      [
        fieldHeight,
        fieldWidth,
        ingredientBounds.height,
        ingredientBounds.width,
        pause,
      ]
    );

    return (
      <animated.div
        {...props}
        ref={mergeRefs([ingredientRef, ingredientMeasureRef, ref])}
        style={style}>
        {children}
      </animated.div>
    );
  }
);

export default Falling;
